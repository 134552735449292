import { gql } from '@apollo/client'
import { Box, ButtonBase, createStyles, Divider, MenuItem, Theme, Toolbar } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MessageIcon from '@material-ui/icons/Message'
import NotificationsIcon from '@material-ui/icons/Notifications'
import isNil from 'lodash/isNil'
import {
  AuthRole,
  getSheetColumnLetter,
  idToCursor,
  NotificationType,
  PositionType,
  checkAuth,
  byteToMB,
  UploadLimit,
} from '@persol-epdndo/base-shared'
import {
  Avatar,
  Menu,
  NavigationBar,
  NotificationBadge,
  NotificationMenu,
  Tab,
  BulkImportErrorDialog,
  Tooltip,
  Switch,
  GroupIcon,
  ChangeModeDialog,
} from '@persol-epdndo/design-systems'
import _ from 'lodash'

import { useRouter } from 'next/router'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { mutations } from '../lib/state'
import {
  MutationBulkImportCareerScouts,
  MutationBulkImportCareerScoutsResponse,
  MutationBulkImportDoubleworks,
  MutationBulkImportDoubleworksResponse,
  MutationBulkImportPositions,
  MutationBulkImportPositionsResponse,
  MutationBulkMarkAsReadEntryMessages,
  MutationBulkMarkAsReadEntryMessagesResponse,
  MutationBulkMarkAsReadDoubleworkEntryMessages,
  MutationBulkMarkAsReadDoubleworkEntryMessagesResponse,
  MutationBulkMarkAsReadNotifications,
  MutationBulkMarkAsReadNotificationsResponse,
  MutationMarkAsReadNotifications,
  MutationMarkAsReadNotificationsResponse,
  MutationSignedUrl,
  MutationSignedUrlResponse,
  MutationSyncWebPushEndpoint,
  MutationSyncWebPushEndpointResponse,
  QueryEntryMessageNotifications,
  QueryEntryMessageNotificationsResponse,
  QueryNotifications,
  QueryNotificationsResponse,
  QueryPrivatePageNavigation,
  QueryPrivatePageNavigationResponse,
  SubscriptionNewEntryMessageNotification,
  SubscriptionNewEntryMessageNotificationResponse,
  SubscriptionNewNotification,
  SubscriptionNewNotificationResponse,
} from '../queries'
import {
  useMutation,
  useQuery,
  moveIfDifferentPath,
  getSystemModeMenus,
  checkSystemModePath,
  getTopPage,
  getSystemModeTopPage,
} from '../util'
import { useVideoContext } from '../providers/VideoProvider'

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    rightToolbar: {
      marginLeft: 'auto',
      display: 'flex',
      placeItems: 'center',
    },
    centerToolbar: {
      marginLeft: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
)

function getIsAdminAndManager(roles: AuthRole[]) {
  if (roles.includes('AccountAdmin') || roles.includes('OrganizationAdmin') || roles.includes('OrganizationManager')) {
    return true
  }
  return false
}

function getIsDoubleworkPublisher(roles: AuthRole[]) {
  if (
    roles.includes('DoubleworkAccountAdmin') ||
    roles.includes('OrganizationDWPubliserAdmin') ||
    roles.includes('OrganizationDWPubliserAssistant')
  ) {
    return true
  }
  return false
}

function getIsDoubleworkPublisherAndAdmin(roles: AuthRole[]) {
  if (
    roles.includes('DoubleworkAccountAdmin') ||
    roles.includes('OrganizationDWPubliserAdmin') ||
    roles.includes('OrganizationDWUserAdmin')
  ) {
    return true
  }
  return false
}

function getIsAdminOnly(roles: AuthRole[]) {
  if (roles.includes('AccountAdmin') || roles.includes('OrganizationAdmin')) {
    return true
  }
  return false
}

function getIsAccountAdmin(roles: AuthRole[]) {
  if (roles.includes('AccountAdmin')) {
    return true
  }
  return false
}

function getIsSmyleAccountAdmin(roles: AuthRole[]) {
  if (roles.includes('SmyleAccountAdmin')) {
    return true
  }
  return false
}

export interface NotificationMenuItem {
  id: string
  message: string
  url: string
  createdAt: Date
  isRead: boolean
  type?: NotificationType
  avatar?: string
  fileKey?: string
}

export interface PrivatePageNavigationProps {
  children?: React.ReactNode
  key?: string | number
}

export const PrivatePageNavigation: FC<PrivatePageNavigationProps> = ({ key = 'PrivatePageNavigation', ...props }) => {
  const router = useRouter()

  // 複業の視聴必須動画を表示するためのContext
  const videoContextValue = useVideoContext()

  const handlePepVisibility = useCallback(
    _.debounce((pathname: string) => {
      const pepIframes = document.querySelectorAll('iframe#synalio-iframe')
      if (pepIframes.length !== 0) {
        if (pepIframes[0].parentElement !== null) {
          pepIframes[0].parentElement.style.display = pathname === '/mypage' ? '' : 'none'
        }
        if (pepIframes[1]?.parentElement !== null && pepIframes[1]?.parentElement !== undefined) {
          pepIframes[1].parentElement.style.display = 'none'
        }
      }
    }, 200),
    [],
  )

  useEffect(() => {
    // NOTE:
    // - pepというチャットボットを表示するjsタグの対応。
    // - 画面ごと表示、非表示を切り替える機能が提供されていないらしく、ここで無理やり制御する。
    // - 2つのiframeで表示されている。その親タグのdisplayを切り替えることで制御する。
    // - 2つのiframeのうち2番目のやつは常に非表示にしておかないとレイアウトが崩れるので、そのようにしている。
    // - jsタグ側の初期化処理が完了する前に`display: none`にしてしまうと、それ以降displayを変更しても表示されなくなってしまう
    //   - jsタグ側の実装の問題だと思われる
    // - なのでデフォルトは表示で、mypage以外だったら隠す、という処理にしている
    // - また、jsタグ側の処理化が終わるまで800ms待つようにsetTimeoutしている
    // - リダイレクト時などの挙動も怪しいので200msでdebounceして、連続してdisplayを切り替えないようにしている
    // - PrivatePageNavigationで読み込んでいたが、mypage以外で初期表示した時に一瞬表示されてしまうので、scriptはmypageで読み込むようにした
    router.events.on('routeChangeStart', url => {
      handlePepVisibility(url)
    })
    setTimeout(() => {
      handlePepVisibility(router.pathname)
    }, 800)

    subscribeToMoreMessage<SubscriptionNewEntryMessageNotificationResponse>({
      document: SubscriptionNewEntryMessageNotification,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData.data === undefined) {
          return prev
        }
        const item = subscriptionData.data.newEntryMessageNotification
        // NOTE: useEffect内であれば以下の処理は不要そうだが念のため残しておく
        if (prev.entryMessageNotifications.items.some(x => x.id === item.id)) {
          return prev
        }
        return Object.assign({}, prev, {
          entryMessageNotifications: {
            items: [...prev.entryMessageNotifications.items, item],
            hasNext: prev.entryMessageNotifications.hasNext,
            endCursor: prev.entryMessageNotifications.endCursor,
            startCursor: idToCursor(item.id),
          },
        })
      },
    })

    subscribeToMoreNotification<SubscriptionNewNotificationResponse>({
      document: SubscriptionNewNotification,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData.data === undefined) {
          return prev
        }
        const item = subscriptionData.data.newNotification
        /// NOTE: useEffect内であれば以下の処理は不要そうだが念のため残しておく
        if (prev.notifications.items.some(x => x.id === item.id)) {
          return prev
        }
        return Object.assign({}, prev, {
          notifications: {
            items: [...prev.notifications.items, item],
            hasNext: prev.notifications.hasNext,
            endCursor: prev.notifications.endCursor,
            startCursor: idToCursor(item.id),
          },
        })
      },
    })
    ;(async () => {
      try {
        // 通知が拒否されている場合は処理を中断
        const webpushDenied = await navigator.permissions.query({ name: 'notifications' }).then(permission => {
          return permission.state === 'denied'
        })
        if (webpushDenied) return

        const publicVapidKey = process.env.NEXT_PUBLIC_PUBLIC_VAPID_KEY as string
        const register = await navigator.serviceWorker.register('/sw.js', {
          scope: '/',
        })
        const subscription = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: publicVapidKey,
        })
        const json = subscription.toJSON()
        await syncWebPushSubscribe({
          variables: { input: { endpoint: json.endpoint, p256dhKey: json.keys?.p256dh, authKey: json.keys?.auth } },
        })
      } catch (error) {
        console.error(error)
      }
    })()
  }, [])

  const [anchorEl1, setAnchorEl1] = useState<HTMLElement | null>(null)
  const [anchorEl2, setAnchorEl2] = useState<HTMLElement | null>(null)
  const [anchorEl3, setAnchorEl3] = useState<HTMLElement | null>(null)
  const [anchorEl4, setAnchorEl4] = useState<HTMLElement | null>(null)
  const [anchorEl5, setAnchorEl5] = useState<HTMLElement | null>(null)
  const [anchorEl6, setAnchorEl6] = useState<HTMLElement | null>(null)
  const [systemMenuAnchors, setSystemMenuAnchors] = useState<Array<HTMLElement | null>>([])
  const [findCandidateAnchorEl, setFindCandidateAnchorEl] = useState<HTMLElement | null>(null)
  const [avatarAnchorEl, setAvatarAnchorEl] = useState<HTMLElement | null>(null)
  const [messageAnchorEl, setMessageAnchorEl] = useState<HTMLElement | null>(null)
  const [isLoadingMessage, setIsLoadingMessage] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
  const [errors, setErrors] = useState<
    Array<{
      column: string
      row: number | string
      message: string
      value: string
    }>
  >([])
  const [notificationAnchorEl, setNotificationAnchorEl] = useState<HTMLElement | null>(null)
  const [isLoadingNotification, setIsLoadingNotification] = useState(false)
  const [bulkImportPositionType, setBulkImportPositionType] = useState<PositionType>(PositionType.CareerCharenge)
  const inputBulkImportPositionsRef = useRef<HTMLInputElement>(null)
  const inputBulkImportDoubleworksRef = useRef<HTMLInputElement>(null)
  const inputBulkImportCareerScoutsRef = useRef<HTMLInputElement>(null)
  const [modeChangeUrl, setModeChangeUrl] = useState<string | null>(null)

  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [signedUrl] = useMutation<MutationSignedUrlResponse>(MutationSignedUrl)
  const [bulkImportPositions] = useMutation<MutationBulkImportPositionsResponse>(MutationBulkImportPositions)
  const [bulkImportDoubleworks] = useMutation<MutationBulkImportDoubleworksResponse>(MutationBulkImportDoubleworks)
  const [bulkImportCareerScouts] = useMutation<MutationBulkImportCareerScoutsResponse>(MutationBulkImportCareerScouts)
  const { data, loading } = useQuery<QueryPrivatePageNavigationResponse>(QueryPrivatePageNavigation)
  const {
    data: entryMessageNotificationsData,
    loading: entryMessageNotificationsLoading,
    subscribeToMore: subscribeToMoreMessage,
    fetchMore: fetchMoreMessageNotifications,
    client: apolloClientEntryMessageNotifications,
  } = useQuery<QueryEntryMessageNotificationsResponse>(QueryEntryMessageNotifications)

  const {
    data: notificationsData,
    loading: notificationsLoading,
    subscribeToMore: subscribeToMoreNotification,
    fetchMore: fetchMoreNotification,
    client: apolloClientNotifications,
  } = useQuery<QueryNotificationsResponse>(QueryNotifications)

  const [markAsReadNotifications] = useMutation<MutationMarkAsReadNotificationsResponse>(
    MutationMarkAsReadNotifications,
  )

  const [bulkMarkAsReadEntryMessages] = useMutation<MutationBulkMarkAsReadEntryMessagesResponse>(
    MutationBulkMarkAsReadEntryMessages,
  )

  const [bulkMarkAsReadDoubleworkEntryMessages] = useMutation<MutationBulkMarkAsReadDoubleworkEntryMessagesResponse>(
    MutationBulkMarkAsReadDoubleworkEntryMessages,
  )

  const [bulkMarkAsReadNotifications] = useMutation<MutationBulkMarkAsReadNotificationsResponse>(
    MutationBulkMarkAsReadNotifications,
  )

  const [syncWebPushSubscribe] = useMutation<MutationSyncWebPushEndpointResponse>(MutationSyncWebPushEndpoint)

  if (
    loading ||
    data === undefined ||
    data.localState.pa === null ||
    entryMessageNotificationsLoading ||
    entryMessageNotificationsData === undefined ||
    notificationsLoading ||
    notificationsData === undefined
  ) {
    return <></>
  }

  const handleInputBulkImportPositionsChange: React.ChangeEventHandler<HTMLInputElement> = async ({ target }) => {
    const files = target.files
    if (files === null || files.length === 0) {
      return
    }
    if (files.length > UploadLimit.maxFiles) {
      return enqueueSnackbar(`ファイル数が${UploadLimit.maxFiles}個を超えているためアップロードできません`)
    }
    const sizeOverFiles = Array.from(files).filter(x => x.size > UploadLimit.maxFileSize)
    if (sizeOverFiles.length > 0) {
      enqueueSnackbar(
        `${byteToMB(UploadLimit.maxFileSize)}MBを超えるファイルはアップロードできません(${sizeOverFiles
          .map(x => x.name)
          .join(', ')})`,
      )
      return
    }

    let resp
    try {
      resp = await bulkImportPositions({ variables: { positionType: bulkImportPositionType, files } })
    } catch {
      return
    } finally {
      target.value = ''
    }
    if (resp.data !== undefined && resp.data !== null) {
      const result = resp.data.bulkImportPositions
      if (result.errors.length === 0) {
        enqueueSnackbar(
          `${result.created + result.updated}件のポジションをインポートしました(${result.created}件登録、${
            result.updated
          }件更新)`,
        )
        // NOTE: manage/positions側でquery.rfを見てrefetchする
        if (result.created + result.updated > 0) {
          router.push({ pathname: '/manage/positions', query: { ...router.query, rf: true } })
        }
      } else {
        const errors = result.errors.map(error => {
          return {
            column: isNil(error.column) ? '' : getSheetColumnLetter(Number.parseInt(error.column, 10)),
            row: isNil(error.row) ? '' : error.row,
            value: isNil(error.value) ? '' : error.value,
            message: error.message,
          }
        })
        setOpenErrorModal(true)
        setErrors(errors)
      }
    }
  }

  const handleInputBulkImportDoubleworksChange: React.ChangeEventHandler<HTMLInputElement> = async ({ target }) => {
    const file = target.files === null ? null : target.files[0]
    if (file === null) {
      return
    }
    if (file.size > UploadLimit.maxFileSize) {
      enqueueSnackbar(`${byteToMB(UploadLimit.maxFileSize)}MBを超えるファイルはアップロードできません`)
      return
    }

    let resp
    try {
      resp = await bulkImportDoubleworks({ variables: { file } })
    } catch {
      return
    } finally {
      target.value = ''
    }
    if (resp.data !== undefined && resp.data !== null) {
      const result = resp.data.bulkImportDoubleworks
      if (result.errors.length === 0) {
        enqueueSnackbar(
          `${result.created + result.updated}件の案件をインポートしました(${result.created}件登録、${
            result.updated
          }件更新)`,
        )
        // NOTE: manage/doubleworks側でquery.rfを見てrefetchする
        if (result.created + result.updated > 0) {
          router.push({ pathname: '/manage/doubleworks', query: { ...router.query, rf: true } })
        }
      } else {
        const errors = result.errors.map(error => {
          return {
            column: isNil(error.column) ? '' : getSheetColumnLetter(Number.parseInt(error.column, 10)),
            row: isNil(error.row) ? '' : error.row,
            value: isNil(error.value) ? '' : error.value,
            message: error.message,
          }
        })
        setOpenErrorModal(true)
        setErrors(errors)
      }
    }
  }

  const handleInputBulkImportCareerScoutsChange: React.ChangeEventHandler<HTMLInputElement> = async ({ target }) => {
    const files = target.files
    if (files === null || files.length === 0) {
      return
    }
    if (files.length > UploadLimit.maxFiles) {
      return enqueueSnackbar(`ファイル数が${UploadLimit.maxFiles}個を超えているためアップロードできません`)
    }
    const sizeOverFiles = Array.from(files).filter(x => x.size > UploadLimit.maxFileSize)
    if (sizeOverFiles.length > 0) {
      enqueueSnackbar(
        `${byteToMB(UploadLimit.maxFileSize)}MBを超えるファイルはアップロードできません(${sizeOverFiles
          .map(x => x.name)
          .join(', ')})`,
      )
      return
    }

    let resp
    try {
      resp = await bulkImportCareerScouts({ variables: { positionType: bulkImportPositionType, files } })
    } catch {
      return
    } finally {
      target.value = ''
    }
    if (resp.data !== undefined && resp.data !== null) {
      const result = resp.data.bulkImportCareerScouts
      if (result.errors.length === 0) {
        enqueueSnackbar(
          `${result.created + result.updated}件のポジションをインポートしました(${result.created}件登録、${
            result.updated
          }件更新)`,
        )
        // NOTE: manage/careerScouts側でquery.rfを見てrefetchする
        if (result.created + result.updated > 0) {
          router.push({ pathname: '/manage/careerScouts', query: { ...router.query, rf: true } })
        }
      } else {
        const errors = result.errors.map(error => {
          return {
            column: isNil(error.column) ? '' : getSheetColumnLetter(Number.parseInt(error.column, 10)),
            row: isNil(error.row) ? '' : error.row,
            value: isNil(error.value) ? '' : error.value,
            message: error.message,
          }
        })
        setOpenErrorModal(true)
        setErrors(errors)
      }
    }
  }

  const markAsReadEntryMessageFragment = (entryMessageId: string) => {
    apolloClientEntryMessageNotifications.writeFragment({
      id: `EntryMessage:${entryMessageId}`,
      fragment: gql`
        fragment PartialEntryMessage on EntryMessage {
          isRead
        }
      `,
      data: {
        isRead: true,
      },
    })
  }

  const markAsReadDoubleworkEntryMessageFragment = (doubleworkEntryMessageId: string) => {
    apolloClientEntryMessageNotifications.writeFragment({
      id: `DoubleworkEntryMessage:${doubleworkEntryMessageId}`,
      fragment: gql`
        fragment PartialDoubleworkEntryMessage on DoubleworkEntryMessage {
          isRead
        }
      `,
      data: {
        isRead: true,
      },
    })
  }

  const markAsReadCareerScoutEntryMessageFragment = (careerScoutEntryMessageId: string) => {
    apolloClientEntryMessageNotifications.writeFragment({
      id: `CareerScoutEntryMessage:${careerScoutEntryMessageId}`,
      fragment: gql`
        fragment PartialCareerScoutEntryMessage on CareerScoutEntryMessage {
          isRead
        }
      `,
      data: {
        isRead: true,
      },
    })
  }

  const markAsReadNotificationFragment = (notificationId: string) => {
    apolloClientNotifications.writeFragment({
      id: `Notification:${notificationId}`,
      fragment: gql`
        fragment PartialNotification on Notification {
          isRead
        }
      `,
      data: {
        isRead: true,
      },
    })
  }

  const updateSystemMenuAnchor = (index: number, value: HTMLElement | null) => {
    setSystemMenuAnchors(prev => {
      const updatedAnchors = prev.length > index ? [...prev] : [...prev, ...Array(index + 1 - prev.length).fill(null)]
      updatedAnchors[index] = value
      return updatedAnchors
    })
  }

  const systemMenus = getSystemModeMenus(data.localState.pa.roles)
  const availableSystemMode = systemMenus.length > 0
  const isSystemMode = checkSystemModePath() && availableSystemMode
  const topPage = getTopPage(data.localState.pa)

  return (
    <>
      <NavigationBar>
        <ButtonBase
          disableRipple
          onClick={() => {
            router.push(topPage)
          }}
        >
          <img src='/persol_navigation_bar_logo.svg' />
          {isSystemMode && <GroupIcon style={{ marginLeft: '16px' }} />}
        </ButtonBase>
        {isSystemMode ? (
          <section className={classes.centerToolbar}>
            {systemMenus.map((x, index) => {
              const anchorEl = systemMenuAnchors[index]
              return (
                <React.Fragment key={index}>
                  <Tab
                    label={x.label}
                    onClick={(e: { currentTarget: HTMLElement | null }) => {
                      updateSystemMenuAnchor(index, e.currentTarget)
                    }}
                  />
                  <Menu
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => {
                      updateSystemMenuAnchor(index, null)
                    }}
                    data-testid={x.testId}
                  >
                    {x.items.map((item, menuIndex) => {
                      return (
                        <MenuItem
                          key={menuIndex}
                          onClick={() => {
                            router.push(item.url)
                            updateSystemMenuAnchor(index, null)
                          }}
                        >
                          {item.label}
                        </MenuItem>
                      )
                    })}
                  </Menu>
                </React.Fragment>
              )
            })}
          </section>
        ) : (
          <section className={classes.centerToolbar}>
            <Tab
              label='マイキャリア'
              onClick={(e: { currentTarget: React.SetStateAction<HTMLElement | null> }) => {
                setAnchorEl1(e.currentTarget)
              }}
            />
            <Menu
              open={Boolean(anchorEl1)}
              anchorEl={anchorEl1}
              onClose={() => setAnchorEl1(null)}
              data-testid='search-position-menu'
            >
              <MenuItem
                onClick={() => {
                  router.push('/resume')
                  setAnchorEl1(null)
                }}
              >
                マイプロフィール
              </MenuItem>
              <MenuItem
                onClick={() => {
                  router.push('/offers')
                  setAnchorEl1(null)
                }}
              >
                スカウト一覧
              </MenuItem>
              <MenuItem
                onClick={() => {
                  router.push('/entries')
                  setAnchorEl1(null)
                }}
              >
                マイエントリー
              </MenuItem>
              <MenuItem
                onClick={() => {
                  router.push('/consultations/request')
                  setAnchorEl1(null)
                }}
              >
                キャリア相談
              </MenuItem>
            </Menu>
            {checkAuth(data.localState.pa, [AuthRole.DoubleworkEntryUser, AuthRole.OrganizationDWUserAdmin]) ? (
              <>
                <Tab
                  label='ポジションを探す'
                  onClick={(e: { currentTarget: React.SetStateAction<HTMLElement | null> }) => {
                    setAnchorEl6(e.currentTarget)
                  }}
                />
                <Menu
                  open={Boolean(anchorEl6)}
                  anchorEl={anchorEl6}
                  onClose={() => setAnchorEl6(null)}
                  data-testid='search-position-menu'
                >
                  <MenuItem
                    onClick={() => {
                      if (router.pathname === '/positions') {
                        return
                      } else {
                        router.push('/positions')
                      }
                      setAnchorEl6(null)
                    }}
                  >
                    キャリアチャレンジ / ジョブトライアル
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      if (router.pathname === '/doubleworks') {
                        return
                      } else {
                        router.push('/doubleworks')
                      }
                      setAnchorEl6(null)
                    }}
                  >
                    グループ内複業
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Tab
                label='ポジションを探す'
                onClick={() => {
                  if (router.pathname === '/positions') {
                  } else {
                    router.push('/positions')
                  }
                }}
              />
            )}
            {!_.isEmpty(data.workshopEntries) && (
              <>
                <Tab
                  label='キャリアを考える'
                  onClick={(e: { currentTarget: React.SetStateAction<HTMLElement | null> }) => {
                    setAnchorEl4(e.currentTarget)
                  }}
                />
                <Menu
                  open={Boolean(anchorEl4)}
                  anchorEl={anchorEl4}
                  onClose={() => setAnchorEl4(null)}
                  data-testid='entry-menu'
                >
                  <MenuItem
                    onClick={() => {
                      moveIfDifferentPath('/workshops')
                      setAnchorEl4(null)
                    }}
                  >
                    Smyle研修
                  </MenuItem>
                </Menu>
              </>
            )}
            {checkAuth(data.localState.pa, [
              AuthRole.ScoutAccountAdmin,
              AuthRole.OrganizationScoutAdmin,
              AuthRole.OrganizationDepartmentManager,
              AuthRole.OrganizationScoutBusinessAdmin,
            ]) && (
              <>
                <Tab
                  label='候補者を探す'
                  onClick={(e: { currentTarget: React.SetStateAction<HTMLElement | null> }) => {
                    setFindCandidateAnchorEl(e.currentTarget)
                  }}
                />
                <Menu
                  open={Boolean(findCandidateAnchorEl)}
                  anchorEl={findCandidateAnchorEl}
                  onClose={() => setFindCandidateAnchorEl(null)}
                  data-testid='find-candidate-menu'
                >
                  <MenuItem
                    onClick={() => {
                      router.push('/manage/resumes')
                      setFindCandidateAnchorEl(null)
                    }}
                  >
                    プロフィール検索
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      router.push('/manage/offers')
                      setFindCandidateAnchorEl(null)
                    }}
                  >
                    メッセージ一覧
                  </MenuItem>
                </Menu>
              </>
            )}
            {(getIsAdminAndManager(data.localState.pa.roles) ||
              getIsDoubleworkPublisher(data.localState.pa.roles) ||
              checkAuth(data.localState.pa, [
                AuthRole.ScoutAccountAdmin,
                AuthRole.OrganizationScoutAdmin,
                AuthRole.OrganizationDepartmentManager,
                AuthRole.OrganizationScoutBusinessAdmin,
              ])) && (
              // eslint-disable-next-line react/jsx-indent
              <>
                <Tab
                  label='ポジションを管理する'
                  onClick={(e: { currentTarget: React.SetStateAction<HTMLElement | null> }) => {
                    setAnchorEl2(e.currentTarget)
                  }}
                />
                <Menu
                  open={Boolean(anchorEl2)}
                  anchorEl={anchorEl2}
                  onClose={() => setAnchorEl2(null)}
                  data-testid='manage-positions-menu'
                >
                  {getIsAdminAndManager(data.localState.pa.roles) && (
                    <div>
                      <MenuItem
                        onClick={() => {
                          moveIfDifferentPath('/manage/positions')
                          setAnchorEl2(null)
                        }}
                      >
                        ポジション一覧 - キャリアチャレンジ / ジョブトライアル
                      </MenuItem>
                      {data.localState.pa.roles.some(x =>
                        ([
                          AuthRole.AccountAdmin,
                          AuthRole.OrganizationCCAdmin,
                          AuthRole.OrganizationManager,
                        ] as AuthRole[]).includes(x),
                      ) && (
                        <MenuItem
                          onClick={() => {
                            router.push('/manage/positions/new')
                            setAnchorEl2(null)
                          }}
                        >
                          ポジション登録 - キャリアチャレンジ
                        </MenuItem>
                      )}
                      {data.localState.pa.roles.some(x =>
                        ([
                          AuthRole.AccountAdmin,
                          AuthRole.OrganizationJTAdmin,
                          AuthRole.OrganizationManager,
                        ] as AuthRole[]).includes(x),
                      ) && (
                        <MenuItem
                          onClick={() => {
                            router.push('/manage/positions/new?type=jt')
                            setAnchorEl2(null)
                          }}
                        >
                          ポジション登録 - ジョブトライアル
                        </MenuItem>
                      )}
                      {data.localState.pa.roles.some(x =>
                        ([AuthRole.AccountAdmin, AuthRole.OrganizationCCAdmin] as AuthRole[]).includes(x),
                      ) && (
                        <MenuItem
                          onClick={() => {
                            setBulkImportPositionType(PositionType.CareerCharenge)
                            router.push('/manage/positions?pt=1')
                            inputBulkImportPositionsRef.current?.click()
                            setAnchorEl2(null)
                          }}
                        >
                          ポジション一括登録 - キャリアチャレンジ
                        </MenuItem>
                      )}
                      {data.localState.pa.roles.some(x =>
                        ([AuthRole.AccountAdmin, AuthRole.OrganizationJTAdmin] as AuthRole[]).includes(x),
                      ) && (
                        <MenuItem
                          onClick={() => {
                            setBulkImportPositionType(PositionType.JobTrial)
                            router.push('/manage/positions?pt=2')
                            inputBulkImportPositionsRef.current?.click()
                            setAnchorEl2(null)
                          }}
                        >
                          ポジション一括登録 - ジョブトライアル
                        </MenuItem>
                      )}
                    </div>
                  )}
                  {getIsDoubleworkPublisher(data.localState.pa.roles) && (
                    <div>
                      {getIsAdminAndManager(data.localState.pa.roles) && <Divider />}
                      <MenuItem
                        onClick={() => {
                          router.push('/manage/doubleworks')
                          setAnchorEl2(null)
                        }}
                      >
                        案件一覧 - グループ内複業
                      </MenuItem>
                      <MenuItem
                        onClick={async () => {
                          // 必須ビデオを使用完了していなければダイアログを表示する
                          if (videoContextValue === null) {
                            console.error('videoContextValue is null')
                            return
                          }
                          setAnchorEl2(null)

                          await videoContextValue.checkVideoCompletedAndOpenDialog(false, () => {
                            router.push('/manage/doubleworks/new')
                          })
                        }}
                      >
                        案件登録 - グループ内複業
                      </MenuItem>
                      <MenuItem
                        onClick={async () => {
                          // 必須ビデオを使用完了していなければダイアログを表示する
                          if (videoContextValue === null) {
                            console.error('videoContextValue is null')
                            return
                          }
                          setAnchorEl2(null)

                          await videoContextValue.checkVideoCompletedAndOpenDialog(false, () => {
                            inputBulkImportDoubleworksRef.current?.click()
                            router.push('/manage/doubleworks')
                          })
                        }}
                      >
                        案件一括登録 - グループ内複業
                      </MenuItem>
                    </div>
                  )}
                  {checkAuth(data.localState.pa, [
                    AuthRole.ScoutAccountAdmin,
                    AuthRole.OrganizationScoutAdmin,
                    AuthRole.OrganizationDepartmentManager,
                    AuthRole.OrganizationScoutBusinessAdmin,
                  ]) && (
                    <div>
                      {(getIsAdminAndManager(data.localState.pa.roles) ||
                        getIsDoubleworkPublisher(data.localState.pa.roles)) && <Divider />}
                      <MenuItem
                        onClick={() => {
                          moveIfDifferentPath('/manage/careerScouts')
                          setAnchorEl2(null)
                        }}
                      >
                        ポジション一覧 - キャリアスカウト
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          router.push('/manage/careerScouts/new')
                          setAnchorEl2(null)
                        }}
                      >
                        ポジション登録 - キャリアスカウト
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          inputBulkImportCareerScoutsRef.current?.click()
                          router.push('/manage/careerScouts')
                          setAnchorEl2(null)
                        }}
                      >
                        ポジション一括登録 - キャリアスカウト
                      </MenuItem>
                    </div>
                  )}
                </Menu>
              </>
            )}
            {(getIsAdminAndManager(data.localState.pa.roles) ||
              getIsDoubleworkPublisherAndAdmin(data.localState.pa.roles) ||
              checkAuth(data.localState.pa, [
                AuthRole.ScoutAccountAdmin,
                AuthRole.OrganizationScoutAdmin,
                AuthRole.OrganizationDepartmentManager,
                AuthRole.OrganizationScoutBusinessAdmin,
              ]) ||
              data.manageEntries.total > 0 ||
              data.manageDoubleworkEntries.total > 0 ||
              data.manageCareerScoutEntries.total > 0) && (
              // eslint-disable-next-line react/jsx-indent
              <>
                <Tab
                  label='候補者を管理する'
                  onClick={(e: { currentTarget: React.SetStateAction<HTMLElement | null> }) => {
                    setAnchorEl3(e.currentTarget)
                  }}
                />
                <Menu
                  open={Boolean(anchorEl3)}
                  anchorEl={anchorEl3}
                  onClose={() => setAnchorEl3(null)}
                  data-testid='entry-menu'
                >
                  {(getIsAdminAndManager(data.localState.pa.roles) || data.manageEntries.total > 0) && (
                    <div>
                      <MenuItem
                        onClick={() => {
                          moveIfDifferentPath('/manage/entries')
                          setAnchorEl3(null)
                        }}
                      >
                        エントリー一覧 - キャリアチャレンジ / ジョブトライアル
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          moveIfDifferentPath('/manage/interviews')
                          setAnchorEl3(null)
                        }}
                      >
                        面接一覧 - キャリアチャレンジ / ジョブトライアル
                      </MenuItem>
                      {getIsAccountAdmin(data.localState.pa.roles) && (
                        <MenuItem
                          onClick={() => {
                            moveIfDifferentPath('/manage/electionPositions')
                            setAnchorEl3(null)
                          }}
                        >
                          ジョブトライアルマッチング
                        </MenuItem>
                      )}
                    </div>
                  )}
                  {(getIsDoubleworkPublisherAndAdmin(data.localState.pa.roles) ||
                    data.manageDoubleworkEntries.total > 0) && (
                    // eslint-disable-next-line react/jsx-indent
                    <div>
                      {(getIsAdminAndManager(data.localState.pa.roles) || data.manageEntries.total > 0) && <Divider />}
                      <MenuItem
                        onClick={() => {
                          router.push('/manage/doubleworkEntries')
                          setAnchorEl3(null)
                        }}
                      >
                        エントリー一覧 - グループ内複業
                      </MenuItem>
                    </div>
                  )}
                  {(checkAuth(data.localState.pa, [
                    AuthRole.ScoutAccountAdmin,
                    AuthRole.OrganizationScoutAdmin,
                    AuthRole.OrganizationDepartmentManager,
                    AuthRole.OrganizationScoutBusinessAdmin,
                  ]) ||
                    data.manageCareerScoutEntries.total > 0) && (
                    // eslint-disable-next-line react/jsx-indent
                    <div>
                      {(getIsAdminAndManager(data.localState.pa.roles) ||
                        getIsDoubleworkPublisherAndAdmin(data.localState.pa.roles) ||
                        data.manageDoubleworkEntries.total > 0 ||
                        data.manageEntries.total > 0) && <Divider />}
                      <MenuItem
                        onClick={() => {
                          moveIfDifferentPath('/manage/careerScoutEntries')
                          setAnchorEl3(null)
                        }}
                      >
                        エントリー一覧 - キャリアスカウト
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          moveIfDifferentPath('/manage/careerScoutInterviews')
                          setAnchorEl3(null)
                        }}
                      >
                        面接一覧 - キャリアスカウト
                      </MenuItem>
                    </div>
                  )}
                </Menu>
              </>
            )}
            {getIsSmyleAccountAdmin(data.localState.pa.roles) && (
              <>
                <Tab
                  label='研修を管理する'
                  onClick={(e: { currentTarget: React.SetStateAction<HTMLElement | null> }) => {
                    setAnchorEl5(e.currentTarget)
                  }}
                />
                <Menu
                  open={Boolean(anchorEl5)}
                  anchorEl={anchorEl5}
                  onClose={() => setAnchorEl5(null)}
                  data-testid='entry-menu'
                >
                  <MenuItem
                    onClick={() => {
                      moveIfDifferentPath('/manage/workshops/unconfirmedEntries')
                      setAnchorEl5(null)
                    }}
                  >
                    エントリー一覧 - Smyle研修
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      moveIfDifferentPath('/manage/workshops/entries')
                      setAnchorEl5(null)
                    }}
                  >
                    参加者一覧 - Smyle研修
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      moveIfDifferentPath('/manage/workshops/surveys')
                      setAnchorEl5(null)
                    }}
                  >
                    アンケート一覧 - Smyle研修
                  </MenuItem>
                </Menu>
              </>
            )}
          </section>
        )}

        <section className={classes.rightToolbar}>
          <NotificationBadge
            data-testid='notification-badge-message'
            variant={
              entryMessageNotificationsData.entryMessageNotifications.items.some(
                x =>
                  x.entryMessage?.isRead === false ||
                  x.doubleworkEntryMessage?.isRead === false ||
                  x.careerScoutEntryMessage?.isRead === false,
              )
                ? 'dotted'
                : 'none'
            }
            onClick={event => setMessageAnchorEl(event.currentTarget)}
          >
            <MessageIcon />
          </NotificationBadge>
          <NotificationMenu
            {...props}
            notifications={entryMessageNotificationsData.entryMessageNotifications.items.map(x => {
              if (x.entryMessage !== null) {
                return {
                  ...x,
                  entryMessageId: x.entryMessage.id,
                  isRead: x.entryMessage.isRead,
                  avatar: x.entryMessage.senderUserAvatarUrl,
                  isUseAdminAvatar: x.entryMessage.isUseAdminAvatar,
                }
              }
              if (x.doubleworkEntryMessage !== null) {
                return {
                  ...x,
                  doubleworkEntryMessageId: x.doubleworkEntryMessage?.id ?? '',
                  isRead: x.doubleworkEntryMessage?.isRead ?? true,
                  avatar: x.doubleworkEntryMessage?.senderUserAvatarUrl ?? '',
                  isUseAdminAvatar: x.doubleworkEntryMessage?.isUseAdminAvatar ?? false,
                }
              }
              return {
                ...x,
                careerScoutEntryMessageId: x.careerScoutEntryMessage?.id ?? '',
                isRead: x.careerScoutEntryMessage?.isRead ?? true,
                avatar: x.careerScoutEntryMessage?.senderUserAvatarUrl ?? '',
                isUseAdminAvatar: x.careerScoutEntryMessage?.isUseAdminAvatar ?? false,
              }
            })}
            hasNext={entryMessageNotificationsData.entryMessageNotifications.hasNext}
            isLoading={isLoadingMessage}
            open={Boolean(messageAnchorEl)}
            anchorEl={messageAnchorEl}
            onClose={() => setMessageAnchorEl(null)}
            onLoadMore={async () => {
              setIsLoadingMessage(true)
              try {
                await fetchMoreMessageNotifications({
                  variables: {
                    before: null,
                    after: entryMessageNotificationsData.entryMessageNotifications.endCursor,
                  },
                })
              } catch {}
              setIsLoadingMessage(false)
            }}
            emptyMessage='メッセージはありません'
            onClickItem={x => {
              markAsReadEntryMessageFragment(x.entryMessageId ?? '')
              markAsReadDoubleworkEntryMessageFragment(x.doubleworkEntryMessageId ?? '')
              markAsReadCareerScoutEntryMessageFragment(x.careerScoutEntryMessageId ?? '')
              let pathname = ''
              try {
                pathname = new URL(x.url).pathname
              } catch {}
              if (checkSystemModePath(pathname) === checkSystemModePath()) {
                router.push(x.url)
              } else {
                setModeChangeUrl(x.url)
                return
              }
              setMessageAnchorEl(null)
            }}
            isUnread={entryMessageNotificationsData.entryMessageNotifications.items.some(
              x => !(x.entryMessage?.isRead === true || x.doubleworkEntryMessage?.isRead === true),
            )}
            onClickBulkRead={async () => {
              setMessageAnchorEl(null)
              let res1
              let res2
              try {
                ;[res1, res2] = await Promise.all([
                  bulkMarkAsReadEntryMessages(),
                  bulkMarkAsReadDoubleworkEntryMessages(),
                ])
              } catch {
                return
              }
              res1.data?.bulkMarkAsReadEntryMessages.map(entryMessageId =>
                markAsReadEntryMessageFragment(entryMessageId),
              )
              res2.data?.bulkMarkAsReadDoubleworkEntryMessages.map(doubleworkEntryMessageId =>
                markAsReadDoubleworkEntryMessageFragment(doubleworkEntryMessageId),
              )

              entryMessageNotificationsData.entryMessageNotifications.items.map(x => {
                if (x.entryMessage !== null) {
                  markAsReadEntryMessageFragment(x.entryMessage.id)
                } else if (x.doubleworkEntryMessage !== null) {
                  markAsReadDoubleworkEntryMessageFragment(x.doubleworkEntryMessage.id)
                } else if (x.careerScoutEntryMessage !== null) {
                  markAsReadCareerScoutEntryMessageFragment(x.careerScoutEntryMessage.id)
                }
              })
            }}
          />
          <NotificationBadge
            data-testid='notification-badge-notification'
            variant={notificationsData.notifications.items.some(x => !x.isRead) ? 'dotted' : 'none'}
            onClick={event => setNotificationAnchorEl(event.currentTarget)}
          >
            <NotificationsIcon />
          </NotificationBadge>
          <NotificationMenu
            {...props}
            notifications={notificationsData.notifications.items.map(x => {
              return { ...x, avatar: x.senderUser?.avatarUrl }
            })}
            hasNext={notificationsData.notifications.hasNext}
            isLoading={isLoadingNotification}
            open={Boolean(notificationAnchorEl)}
            anchorEl={notificationAnchorEl}
            onClose={() => setNotificationAnchorEl(null)}
            onLoadMore={async () => {
              setIsLoadingNotification(true)
              try {
                await fetchMoreNotification({
                  variables: { before: null, after: notificationsData.notifications.endCursor },
                })
              } catch {}
              setIsLoadingNotification(false)
            }}
            emptyMessage='通知はありません'
            onClickItem={async x => {
              try {
                markAsReadNotifications({
                  variables: {
                    input: { notificationIds: [x.id] },
                  },
                })
              } catch {}
              markAsReadNotificationFragment(x.id)
              if (x.type === NotificationType.Common || x.type === NotificationType.Exec) {
                if (x.url !== '') {
                  let pathname = ''
                  try {
                    pathname = new URL(x.url).pathname
                  } catch {}
                  if (checkSystemModePath(pathname) === checkSystemModePath()) {
                    router.push(x.url)
                  } else {
                    setModeChangeUrl(x.url)
                    return
                  }
                }
              } else if (x.type === NotificationType.Download) {
                if (x.fileKey !== undefined) {
                  let resp
                  try {
                    resp = await signedUrl({ variables: { fileKey: x.fileKey } })
                    if (resp.data !== undefined && resp.data !== null) {
                      window.open(resp.data.signedUrl.url, '_blank')
                    }
                  } catch {}
                }
              }
              setNotificationAnchorEl(null)
            }}
            isUnread={notificationsData.notifications.items.some(x => !x.isRead)}
            onClickBulkRead={async () => {
              setNotificationAnchorEl(null)
              let res
              try {
                res = await bulkMarkAsReadNotifications()
              } catch {
                return
              }
              res.data?.bulkMarkAsReadNotifications.map(notificationId =>
                markAsReadNotificationFragment(notificationId),
              )
            }}
          />
          <Box ml={1}>
            <Tooltip title={`${data.me.lastName}${data.me.firstName}`}>
              <ButtonBase onClick={e => setAvatarAnchorEl(e.currentTarget)} disableRipple>
                <Avatar data-testid='avatar' variant='outlined' src={data.me.avatarUrl} />
              </ButtonBase>
            </Tooltip>
            <Menu
              open={Boolean(avatarAnchorEl)}
              anchorEl={avatarAnchorEl}
              onClose={() => setAvatarAnchorEl(null)}
              data-testid='avatar-menu'
            >
              {availableSystemMode && (
                <div>
                  <MenuItem>
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      alignItems='center'
                      justifyContent='space-between'
                      gridColumnGap={24}
                    >
                      <Box>管理者モードで起動</Box>
                      <Box>
                        <Switch
                          size='small'
                          data-testid='system-mode-switch'
                          checked={isSystemMode}
                          onChange={e => {
                            let url = '/mypage'
                            if (!isSystemMode) {
                              url = getSystemModeTopPage(data?.localState?.pa?.roles ?? []) as string
                            }
                            router.push(url)
                            setAvatarAnchorEl(null)
                          }}
                        />
                      </Box>
                    </Box>
                  </MenuItem>
                  <Divider />
                </div>
              )}
              {!isSystemMode && (
                <div>
                  <MenuItem
                    onClick={() => {
                      router.push('/settings')
                      setAvatarAnchorEl(null)
                    }}
                  >
                    設定
                  </MenuItem>
                  {(getIsAdminOnly(data.localState.pa.roles) ||
                    checkAuth(data.localState.pa, [
                      AuthRole.ScoutAccountAdmin,
                      AuthRole.OrganizationScoutAdmin,
                      AuthRole.AccountAdmin,
                      AuthRole.DoubleworkAccountAdmin,
                      AuthRole.SmyleAccountAdmin,
                      AuthRole.OrganizationCCAdmin,
                      AuthRole.OrganizationScoutBusinessAdmin,
                    ]) ||
                    getIsAccountAdmin(data.localState.pa.roles)) && <Divider />}
                  {getIsAdminOnly(data.localState.pa.roles) && (
                    <MenuItem
                      onClick={() => {
                        router.push('/manage/templates')
                        setAvatarAnchorEl(null)
                      }}
                    >
                      テンプレート管理 - キャリアチャレンジ / ジョブトライアル
                    </MenuItem>
                  )}
                  {checkAuth(data.localState.pa, [
                    AuthRole.ScoutAccountAdmin,
                    AuthRole.OrganizationScoutAdmin,
                    AuthRole.OrganizationScoutBusinessAdmin,
                  ]) && (
                    <MenuItem
                      onClick={() => {
                        router.push('/manage/careerScoutTemplates')
                        setAvatarAnchorEl(null)
                      }}
                    >
                      テンプレート管理 - キャリアスカウト
                    </MenuItem>
                  )}
                  {getIsAccountAdmin(data.localState.pa.roles) && (
                    <MenuItem
                      onClick={() => {
                        router.push('/manage/informations')
                        setAvatarAnchorEl(null)
                      }}
                    >
                      お知らせ更新
                    </MenuItem>
                  )}
                  {data.localState.pa.roles.some(x =>
                    ([
                      AuthRole.AccountAdmin,
                      AuthRole.DoubleworkAccountAdmin,
                      AuthRole.SmyleAccountAdmin,
                      AuthRole.ScoutAccountAdmin,
                      AuthRole.OrganizationCCAdmin,
                    ] as AuthRole[]).includes(x),
                  ) && (
                    <MenuItem
                      onClick={() => {
                        router.push('/manage/schedule')
                        setAvatarAnchorEl(null)
                      }}
                    >
                      年間スケジュール更新
                    </MenuItem>
                  )}
                  {getIsAccountAdmin(data.localState.pa.roles) && (
                    <MenuItem
                      onClick={() => {
                        router.push('/manage/adminFiles')
                        setAvatarAnchorEl(null)
                      }}
                    >
                      認証付きURLの生成
                    </MenuItem>
                  )}
                  <Divider />
                </div>
              )}
              <MenuItem
                onClick={() => {
                  mutations.signOut('/')
                  setAvatarAnchorEl(null)
                }}
              >
                ログアウト
              </MenuItem>
            </Menu>
          </Box>
        </section>
      </NavigationBar>
      {/* NOTE: fixedなNavigationBarの下に潜り込まないためのダミーのToolbar */}
      {/* https://material-ui.com/components/app-bar/#fixed-placement */}
      <Toolbar />
      {props.children}
      <input
        ref={inputBulkImportPositionsRef}
        type='file'
        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg'
        multiple
        onChange={handleInputBulkImportPositionsChange}
        style={{ display: 'none' }}
      />
      <input
        ref={inputBulkImportDoubleworksRef}
        type='file'
        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        onChange={handleInputBulkImportDoubleworksChange}
        style={{ display: 'none' }}
      />
      <input
        ref={inputBulkImportCareerScoutsRef}
        type='file'
        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg'
        multiple
        onChange={handleInputBulkImportCareerScoutsChange}
        style={{ display: 'none' }}
      />
      <BulkImportErrorDialog
        open={openErrorModal}
        errors={errors}
        onClose={() => {
          setOpenErrorModal(false)
          setErrors([])
        }}
      />
      <ChangeModeDialog
        open={Boolean(modeChangeUrl)}
        modeLabel={isSystemMode ? 'ユーザー' : '管理者'}
        onClose={() => {
          setModeChangeUrl(null)
        }}
        onExec={() => {
          router.push(modeChangeUrl ?? '')
          setModeChangeUrl(null)
          setNotificationAnchorEl(null)
          setMessageAnchorEl(null)
        }}
      />
    </>
  )
}

PrivatePageNavigation.displayName = 'PrivatePageNavigation'
